html {
    font-size:14px;
}

p{
	margin-top: 15px !important;
}

pre {outline: 1px solid #ccc; padding: 5px; margin: 5px; }
.string { color: green; }
.number { color: darkorange; }
.boolean { color: blue; }
.null { color: magenta; }
.key { color: red; }

.left-input {
    width:2.3rem;
    border-color:rgb(132, 132, 132);
    border-width: 1px 0 1px 1px;
    border-radius: 4px 0 0 4px;
}
input.p-column-filter {
    width:100%
}
body .p-datatable .p-datatable-thead th.p-filter-column {
    padding:0.3rem
}
.p-fieldset .p-fieldset-legend {
    border: 0;
    font-weight: unset;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    background: transparent;
}
body a {
    text-decoration: none;
    color: #007ad9;
}
a:visited {
    color: #007ad9;
}
body {
    /*background-color: grey;*/
    /*overflow-y:hidden;*/
    overflow-y: scroll;
}
body .p-fieldset, body .p-menubar {
    /*background-color: grey;*/
}
body .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.3em 0.55em 0.3em 0.55em;
    /*background-color: grey;*/

}
body .p-datatable .p-datatable-tbody > tr:hover  {
    background-color: gainsboro;
}

body .mtg-chips .p-inputtext {
    width:100%;
}
body .p-chips > ul.p-inputtext .p-chips-token {
    margin: 2px;
}
body .p-chips > ul.p-inputtext .p-chips-token.p-highlight{
    line-height: 1.4rem;
}
.p-splitbutton .p-splitbutton-menubutton {
    flex: 1 1 auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 none;
}
/*body .p-splitbutton .p-button {*/
/*    !*width:8.5rem;*!*/
/*}*/
/*body .p-splitbutton .p-button .p-button-text.p-c{*/
/*    !*margin-left:1rem*!*/
/*}*/

body .p-autocomplete.p-component {
    width:90%;
}
body .p-datatable td.t-right {
    text-align: right;
}
body .p-datatable td.t-center {
    text-align: center;
}
body .mtg-reports-prognose.p-button.p-button-text-only .p-button-text{
    padding: 3px;
    color: #333333;

}
body .mtg-reports-prognose.p-togglebutton {
     background-color: #f4f4f4;
}
body .mtg-reports-prognose.p-togglebutton.p-highlight:hover{
    background-color: #f4f4f4;
}
body .dialog-voucher-orders thead {
    display: none;
}
body .t-strong {
    font-weight: bold;
}
body .t-w-1 {
    width: 1rem;
}
body .t-w-2 {
    width: 2rem;
}
body .t-w-3 {
    width: 3rem;
}
body .t-w-4 {
    width: 4rem;
}
body .t-w-5 {
    width: 5rem;
}
body .t-w-6 {
    width: 6rem;
}
body .t-w-7 {
    width: 7rem;
}
body .t-w-8 {
    width: 8rem;
}
body .t-w-9 {
    width: 9rem;
}
body .t-w-10 {
    width: 10rem;
}
body .t-w-11 {
    width: 11rem;
}
body .t-w-12 {
    width: 12rem;
}
body .t-red-strong {
    font-weight: bold;
    color: red;
}

/* Draft js editor css */
.demo-root {
  width: 90%;
  display: flex;
  font-family: Roboto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.demo-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 50px 0 25px;
}
.demo-label-high {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 50px 0 90px;
}
.demo-subLabel {
  margin-bottom: 20px;
}
.demo-editorSection {
  display: flex;
  justify-content: space-between;
}
.demo-wrapper {
  width: 100% !important;
  display: block !important;
  margin-bottom: 25px !important;
  height: 400px !important;
}
.demo-wrapper-medium {
  width: 650px !important;
  display: block !important;
  margin-bottom: 50px !important;
  height: 300px !important;
}
.demo-editor {
  height: 275px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
.demo-editorWrapper {
}
.demo-content {
  border: 1px solid #F1F1F1;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  border-radius: 2px;
  resize: none;
  height: 200px;
}
.demo-toolbar-absolute {
  position: absolute !important;
  top: -40px !important;
  width: 550px !important;
  border-radius: 3px !important;
  background: #f3f3f3 !important;
  border: 1px solid #e3e3e3 !important;
}
.demo-toolbar-absolute-high {
  position: absolute !important;
  top: -80px !important;
  width: 550px !important;
  border-radius: 3px !important;
  background: #f3f3f3 !important;
  border: 1px solid #e3e3e3 !important;
}
.demo-editor-plain {
  height: 80% !important;
  border-width: 0px !important;
}
.demo-editor-embedded {
  height: 90% !important;
  border-width: 0px !important;
}
.demo-wrapper-relative {
  display: block !important;
  position: relative !important;
  margin-bottom: 25px !important;
  height: 200px !important;
}
.demo-wrapper-relative-long {
  width: 715px !important;
  display: block !important;
  position: relative !important;
  margin-bottom: 25px !important;
  height: 450px !important;
}
.bordered-option-classname {
  border: 1px solid #e3e3e3 !important;
}
.demo-toolbar-absolute > div:not(:last-of-type) {
  border-right: 2px solid #e3e3e3;
  padding-right: 5px;
}
.demo-toolbar-absolute > div:not(:first-of-type) {
  padding-left: 5px;
}
.demo-toolbar-custom {
  border-color: rgb(0,47,126) !important;
  background-color: #9EBEF5 !important;
}
.demo-editor-custom {
  height: 200px !important;
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  height: 75% !important;
  border-radius: 2px !important;
  border-color: rgb(0,47,126) !important;
}
.demo-option-custom {
  width: 15px !important;
  height: 15px !important;
  border-color: rgb(0,47,126) !important;
  color: rgb(0,47,126);
}
.demo-option-custom-wide {
  width: 125px !important;
  height: 25px !important;
  border-color: rgb(0,47,126) !important;
  color: rgb(0,47,126) !important;
}
.demo-option-custom-medium {
  height: 25px !important;
  border-color: rgb(0,47,126) !important;
  color: rgb(0,47,126) !important;
}
.demo-dropdown-custom {
  border-color: rgb(0,47,126) !important;
}
.demo-popup-custom {
  border-color: rgb(0,47,126) !important;
  padding: 5px !important;
}
.demo-popup-custom input {
  border-color: rgb(0,47,126) !important;
}
.demo-popup-custom button {
  border-color: rgb(0,47,126) !important;
}
.demo-section-wrapper {
  display: flex;
}
.demo-editor-wrapper {
  width: 60%;
}
.demo-section {
  margin: 50px 30px 20px;
  width: 95%;
}
.ReactCodeMirror {
  width: 38%;
  margin-left: 2%;
  border: 1px solid #e4e4e4;
  height: 400px;
}
.CodeMirror {
  height: 400px !important;
}
.demo-custom-option {
  width: 26px;
  height: 26px;
  border: 1px solid #f1f1f1;
  text-align: center;
  padding-right: 5px;
  padding-bottom: 2px;
  cursor: pointer;
}

.docs-root {
  width: calc(100% - 150px);
  margin: 30px;
}
.docs-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0;
}
.docs-sub-label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0;
}
.docs-section {
  margin: 20px 0 50px;
}
.docs-section .ReactCodeMirror {
  max-height: 500px;
  overflow: scroll;
  width: 75%;
  margin-top: 25px;
  margin-left: 0;
  height: auto !important;
}
.docs-section .CodeMirror {
  height: auto !important;
}
code {
  padding: 20px;
  font-size: 13px;
  display: inline-flex;
  flex-direction: column;
  background-color: #f1f1f1;
}
.code_sm {
  padding: 2px;
}
.docs-desc {
  width: 75%;
  font-size: 15px;
  margin-bottom: 10px;
}
.docs-root li {
  margin-bottom: 5px;
}
.docs-text {
  width: 75%;
  font-size: 17px;
  margin: 15px 0;
}
.top-margined {
  margin-top: 20px;
}
ol {
  font-size: 15px;
}
.docs-property {
  display: flex;
}
.author-root {
  margin: 30px;
  width: 50%;
}
.photo {
  height: 150px;
  width: auto;
}

.rdw-option-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}
.rdw-dropdown-wrapper:focus {
  outline: none;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}
.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #F1F1F1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
  background: #F1F1F1;
}
.rdw-dropdownoption-active {
  background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-inline-dropdown {
  width: 50px;
}
.rdw-inline-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-fontsize-dropdown {
  min-width: 40px;
}
.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}

.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-fontfamily-dropdown {
  width: 115px;
}
.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}
.rdw-list-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}
.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-right-aligned-block {
  text-align: right;
}
.rdw-left-aligned-block {
  text-align: left !important;
}
.rdw-center-aligned-block {
  text-align: center !important;
}
.rdw-justify-aligned-block {
  text-align: justify !important;
}
.rdw-right-aligned-block > div {
  display: inline-block;
}
.rdw-left-aligned-block > div {
  display: inline-block;
}
.rdw-center-aligned-block > div {
  display: inline-block;
}
.rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px;
}
.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}
.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll;
}
.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #F1F1F1;
}
.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #BFBDBD inset;
}
.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #BFBDBD;
}
.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #BFBDBD;
}
.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #BFBDBD;
}

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-link-dropdown {
  width: 50px;
}
.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}
.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-link-modal-label {
  font-size: 15px;
}
.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}
.rdw-link-modal-input:focus {
  outline: none;
}
.rdw-link-modal-buttonsection {
  margin: 0 auto;
}
.rdw-link-modal-target-option {
  margin-bottom: 20px;
}
.rdw-link-modal-target-option > span {
  margin-left: 5px;
}
.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-link-modal-btn:focus {
  outline: none !important;
}
.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}
.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rdw-embedded-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}
.rdw-embedded-modal-link-section {
  display: flex;
  flex-direction: column;
}
.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  margin: 10px 0;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}
.rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center;
}
.rdw-embedded-modal-link-input:focus {
  outline: none;
}
.rdw-embedded-modal-btn-section {
  display: flex;
  justify-content: center;
}
.rdw-embedded-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-embedded-modal-btn:focus {
  outline: none !important;
}
.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-embedded-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}
.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}
.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}
.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-image-modal-header {
  font-size: 15px;
  margin: 10px 0;
  display: flex;
}
.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}
.rdw-image-modal-header-label-highlighted {
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}
.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  display: flex;
  border: none;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  outline: 2px dashed gray;
  outline-offset: -10px;
  margin: 10px 0;
  padding: 9px 0;
}
.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}
.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.rdw-image-modal-upload-option-label span{
  padding: 0 20px;
}
.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}
.rdw-image-modal-upload-option-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.rdw-image-modal-url-section {
  display: flex;
  align-items: center;
}
.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  margin: 15px 0 12px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}
.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}
.rdw-image-modal-url-input:focus {
  outline: none;
}
.rdw-image-modal-btn {
  margin: 0 5px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-image-modal-btn:focus {
  outline: none !important;
}
.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-image-modal-spinner {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}
.rdw-image-modal-alt-input:focus {
  outline: none;
}
.rdw-image-modal-alt-lbl {
  font-size: 12px;
}
.rdw-image-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}
.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}
.rdw-image-modal-size-input:focus {
  outline: none;
}
.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}
.rdw-history-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}
.rdw-link-decorator-icon {
  position: absolute;
  left: 40%;
  top: 0;
  cursor: pointer;
  background-color: white;
}

.rdw-mention-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-suggestion-wrapper {
  position: relative;
}
.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #F1F1F1;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white;
  z-index: 100;
}
.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
}
.rdw-suggestion-option-active {
  background-color: #F1F1F1;
}

.rdw-hashtag-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: 105px;
  cursor: pointer;
  z-index: 100;
}
.rdw-alignment-option-left {
  justify-content: flex-start;
}
.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}
.rdw-image-alignment {
  position: relative;
}
.rdw-image-imagewrapper {
  position: relative;
}
.rdw-image-center {
  display: flex;
  justify-content: center;
}
.rdw-image-left {
  display: flex;
}
.rdw-image-right {
  display: flex;
  justify-content: flex-end;
}
.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}
.public-DraftStyleDefault-block {
  margin: 1em 0;
}
.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}
